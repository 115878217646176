.videoCard {
    position: relative;
    color:white;
    width:500px;
    height:400px;
    padding: 20px;
    transition: transform 100ms;
}

.videoCard:hover{
    transform: scale(1.09);
    transition: all 300ms ease;
    cursor: pointer;
}

.videoCard > img {
    height: 300px;
    object-fit: contain;
    width:500px;
}

.videoCard_stats{
    display:none;
}

.videoCard:hover > .videoCard_stats {
    display: inline;
}

.videoCard > h2 {
    padding-top: 10px;
}

.videoCard > .heart{
    position: absolute;
    right: 25px;
    bottom: 25px;
}

.videoCard > .heart:hover{
    cursor:pointer;
}

a{
    text-decoration: none;
    color: white;
}