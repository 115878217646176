.nav{
    display: -webkit-box;
    color: white;
    overflow-x: scroll;
    padding: 10px 10px 10px 40px;
}

.nav::-webkit-scrollbar{
    display: none;
}

.nav{
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.nav > h2 {
    cursor: pointer;
    margin-left: 30px;
    margin-right: 30px;
    font-weight: 500;
    opacity: 0.6;
    transition: transform 50ms ease-out;
}

.nav > h2:hover {
    opacity: 1;
    transform: scale(1.2);
}