.header{
    color:white;
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    overflow-x: scroll;
}

.header::-webkit-scrollbar{
    display: none;
}

.header {
    -ms-overflow-style: none;
    scrollbar-width:none;
}

.header__icon > .MuiSvgIcon-root{
    font-size: 30px;
    margin-left: auto;
    margin-right: auto;
}

.header__icon--active > p {
    display: inline-flex;
}

.header__icon:hover > p {
    display: inline-flex;
}

.header__icon > p {
    display: none;
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-left: auto;
    margin-right: auto;
    font-size: small;
}

.header__icon{
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 100px;
}

.header__icons{
    display: flex;
    align-items: center;
    text-align: center;
}

.header > img{
    object-fit: contain;
    height: 80px;
}

.header > img:hover{
    cursor: pointer;
}

.sb__input{
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(27,27,27,0);
    --swiper-theme-color: #007aff;
    --swiper-navigation-size: 44px;
    --blue: #5488c7;
    --indigo: #6610f2;
    --purple: #613d7c;
    --pink: #ff5b77;
    --red: #f56c6c;
    --orange: #f25f43;
    --yellow: #e6a23c;
    --green: #67c23a;
    --teal: #8eb4cb;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #9b9b9b;
    --gray-dark: #343a40;
    --primary: #5488c7;
    --secondary: #9b9b9b;
    --success: #67c23a;
    --info: #8eb4cb;
    --warning: #e6a23c;
    --danger: #f56c6c;
    --light: #f6f6f7;
    --dark: #0b1a33;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    box-sizing: border-box;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    overflow: visible;
    outline: none;
    line-height: 1;
    width: 30%;
    padding: .65rem calc(45px + 1.5rem) .65rem 1.5rem;
    border-radius: 3px;
    border: 2px solid #d6d6d7;
}